<template>
  <b-card title="Clinical" sub-title="Information about clinical">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ASA classification"
              label-for="asa_class"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.discharge_clinical_ASA_classification"
                id="asa_class"
                type="number"
                placeholder="ASA classification"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="CCS angina pectoris"
              label-for="ccs_angina"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.discharge_clinical_CCS_angina_pectoris"
                id="ccs_angina"
                type="number"
                placeholder="CCS angina pectoris"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="NYHA" label-for="NYHA" label-cols-md="4">
              <v-select
                v-model="patientInfo.discharge_clinical_NYHA"
                label="value"
                :options="options1"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="6 min walk test"
              label-for="walk_test"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.discharge_clinical_6_min_walk_test"
                id="walk_test"
                type="number"
                placeholder="6 min walk test"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Frailty score"
              label-for="frailty_score"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.discharge_clinical_frailty_score"
                id="frailty_score"
                type="number"
                placeholder="Frailty score"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Meld score"
              label-for="meld_score"
              label-cols-md="4"
            >
              <div class="vertical-align">
                {{ patientInfo.discharge_clinical_meld_score }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-form-group
              label="Renal failure"
              label-for="renal_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.discharge_clinical_renal_failure"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Grade" label-for="grade" label-cols-md="4">
              <v-select
                v-model="patientInfo.discharge_clinical_renal_failure_detail"
                label="value"
                :options="options2"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-card class="bordered-card" title="Right heart failure">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Right heart failure"
                label-for="right_heart_failure"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.discharge_clinical_right_heart_failure"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Symptoms"
                label-for="symptoms"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{ symptoms }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Odema" label-for="odema" label-cols-md="4">
                <v-select
                  v-model="
                    patientInfo.discharge_clinical_right_heart_failure_odema
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Ascites"
                label-for="ascites"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.discharge_clinical_right_heart_failure_ascites
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Venous congestion"
                label-for="venous"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.discharge_clinical_right_heart_failure_venous_congestion
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Pleural effusion"
                label-for="pleural effusion"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.discharge_clinical_right_heart_failure_pleural_effusion
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { getMeldScoreForDischargeAndFollowup } from "@/@core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        discharge_clinical_meld_score: "",
        discharge_clinical_ASA_classification: "",
        discharge_clinical_CCS_angina_pectoris: "",
        discharge_clinical_NYHA: "",
        discharge_clinical_6_min_walk_test: "",
        discharge_clinical_frailty_score: "",
        discharge_clinical_renal_failure: "",
        discharge_clinical_renal_failure_detail: "",
        discharge_clinical_right_heart_failure: "",
        discharge_clinical_right_heart_failure_odema: "",
        discharge_clinical_right_heart_failure_ascites: "",
        discharge_clinical_right_heart_failure_venous_congestion: "",
        discharge_clinical_right_heart_failure_pleural_effusion: "",
      },
      options: ["yes", "no"],
      options1: ["I", "II", "III", "IV"],
      options2: [
        "1 GFR: >90ml/min/1,73m^2",
        "2 GFR: 60-89ml/min/1,73m^2",
        "3 GFR: 30-59ml/min/1,73m^2",
        "4 GFR: 15-29ml/min/1,73m^2",
        "5 GFR: <15ml/min/1,73m^2",
      ],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    symptoms() {
      return "";
    },
    discharge_clinical_meld_score() {
      return getMeldScoreForDischargeAndFollowup(
        this.patient.discharge_lab_creatinin,
        this.patient.discharge_lab_bilirubin,
        this.patient.discharge_lab_inr
      );
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    discharge_clinical_meld_score(v, ov) {
      this.patientInfo.discharge_clinical_meld_score = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.discharge_clinical_meld_score =
        v.discharge_clinical_meld_score;
      this.patientInfo.discharge_clinical_ASA_classification =
        v.discharge_clinical_ASA_classification;
      this.patientInfo.discharge_clinical_CCS_angina_pectoris =
        v.discharge_clinical_CCS_angina_pectoris;
      this.patientInfo.discharge_clinical_NYHA = v.discharge_clinical_NYHA;
      this.patientInfo.discharge_clinical_6_min_walk_test =
        v.discharge_clinical_6_min_walk_test;
      this.patientInfo.discharge_clinical_frailty_score =
        v.discharge_clinical_frailty_score;
      this.patientInfo.discharge_clinical_renal_failure =
        v.discharge_clinical_renal_failure;
      this.patientInfo.discharge_clinical_renal_failure_detail =
        v.discharge_clinical_renal_failure_detail;
      this.patientInfo.discharge_clinical_right_heart_failure =
        v.discharge_clinical_right_heart_failure;
      this.patientInfo.discharge_clinical_right_heart_failure_odema =
        v.discharge_clinical_right_heart_failure_odema;
      this.patientInfo.discharge_clinical_right_heart_failure_ascites =
        v.discharge_clinical_right_heart_failure_ascites;
      this.patientInfo.discharge_clinical_right_heart_failure_venous_congestion =
        v.discharge_clinical_right_heart_failure_venous_congestion;
      this.patientInfo.discharge_clinical_right_heart_failure_pleural_effusion =
        v.discharge_clinical_right_heart_failure_pleural_effusion;
    },
  },
};
</script>

<style>
</style>
