<template>
  <b-row class="match-height discharge-tab">
    <b-col md="4">
      <lab ref="lab"></lab>
    </b-col>
    <b-col md="8">
      <clinical ref="clinical"></clinical>
    </b-col>
    <b-col cols="12">
      <echo ref="echo"></echo>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Lab from "./discharge/Lab";
import Clinical from "./discharge/Clinical";
import Echo from "./discharge/Echo";
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/config";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Lab,
    Clinical,
    Echo,
  },
  timers: { updatePatient: config.TIMER_OPTION },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  methods: {
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let patientInfo = {
        ...this.$refs.lab.patientInfo,
        ...this.$refs.clinical.patientInfo,
        ...this.$refs.echo.patientInfo,
      };

      this.updatePatientById(patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.discharge-tab {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
